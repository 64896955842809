import React from 'react'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container text-center text-white py-3">
                <p>© 2024 Cloud Shield | Keeping Hunt: Showdown Fair for All Players</p>
            </div>
        </footer>
    )
}

export default Footer
