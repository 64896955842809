import React, { useState } from 'react';
import './Players.css';
import vacIcon from '../img/vac.jpg';
import priIcon from '../img/pri.png'; 

const Players = () => {
    const [searchTerm, setSearchTerm] = useState('');

 
    const players = [
        { name: 'BLAKE', steamUrl: 'https://steamcommunity.com/profiles/76561198299538461/', hasVacIcon: false, hasPrivateIcon: false, date: '2024-08-20' },
        { name: '[REM]Carlinhos Ranch [REM]Necromanso [Ϡ]Necromans', steamUrl: 'https://steamcommunity.com/profiles/76561198126523816', hasVacIcon: true, hasPrivateIcon: false, date: '2024-08-21' },
        { name: '[REM]Rato[Ϡ]Rato [Ϡ]Rato ϡ ξ [ϟ]Rato◬ ⌘', steamUrl: 'https://steamcommunity.com/profiles/76561198393793217', hasVacIcon: false, hasPrivateIcon: false, date: '2024-08-21' },
        { name: '[REM] - Darker - Victor Calebe', steamUrl: 'https://steamcommunity.com/profiles/76561198126549010', hasVacIcon: false, hasPrivateIcon: false, date: '2024-08-21' },
    ];


    const filteredPlayers = players.filter(player =>
        player.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="players-container">
            <h2>Cheating Players Ranking</h2>
            <p>
                This is the ranking of players most reported for cheating in Hunt: Showdown.
                Each player has been reported multiple times by the community, leading to their inclusion on this list.
                Our goal is to maintain fair competition and ensure the game remains enjoyable for everyone.
                If you encounter a cheater, don't hesitate to report them and help preserve the integrity of the game
            </p>
            <img src={priIcon} alt="Private Icon" className="player-icon" /> Private Profile <img src={vacIcon} alt="VAC Icon" className="player-icon" /> Steam VAC
            <input
                type="text"
                placeholder="Search by player name..."
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul className="players-list">
                {filteredPlayers.map((player, index) => (
                    <li key={index} className="player-item">
                        <span className="player-rank">{index + 1}.</span>
                        <a href={player.steamUrl} target="_blank" rel="noopener noreferrer" className="player-name">
                            {player.name}
                        </a>
                        <div className="player-icons">
                            {player.hasVacIcon && <img src={vacIcon} alt="VAC Icon" className="player-icon" />}
                            {player.hasPrivateIcon && <img src={priIcon} alt="Private Icon" className="player-icon" />}
                        </div>
                        <span className="player-date">{player.date}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Players;
