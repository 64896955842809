import React, { useState, useEffect } from 'react';
import './ReportForm.css';

function ReportForm() {
    const [playerName, setPlayerName] = useState('');
    const [cheatType, setCheatType] = useState('');
    const [description, setDescription] = useState('');
    const [videoLink, setVideoLink] = useState('');
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        document.body.classList.add('report-form-bg');
        return () => {
            document.body.classList.remove('report-form-bg');
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = {
                email,
                playerName,
                videoLink: videoLink || 'No video link provided',
                description: description || 'No description provided',
                cheatType: cheatType || 'No cheat type specified',
            };

            const response = await fetch('/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage(`Your report for player "${playerName}" has been successfully submitted. A confirmation email has been sent to ${email}.`);
                setErrorMessage('');
            } else {
                const data = await response.json();
                setErrorMessage(`Error: ${data.message}`);
            }
        } catch (error) {
            setErrorMessage('An error occurred while sending the email.');
        }

        setPlayerName('');
        setCheatType('');
        setDescription('');
        setVideoLink('');
        setEmail('');
    };

    return (
        <div className="form-container">
            <h2 className="text-center mb-4">Report a Cheater in Hunt: Showdown</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="playerName" className="form-label">Player Name or Identifier</label>
                    <input
                        type="text"
                        className="form-control"
                        id="playerName"
                        placeholder="Enter cheater's player name"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="cheatType" className="form-label">Type of Cheat</label>
                    <select
                        className="form-select"
                        id="cheatType"
                        value={cheatType}
                        onChange={(e) => setCheatType(e.target.value)}
                        required
                    >
                        <option value="">Select a Cheat Type</option>
                        <option value="aimbot">Aimbot</option>
                        <option value="wallhack">Wallhack</option>
                        <option value="esp">ESP (Extra Sensory Perception)</option>
                        <option value="speedhack">Speed Hack</option>
                        <option value="macro">Macro</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">Description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        placeholder="Describe the cheating behavior"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label htmlFor="videoLink" className="form-label">Video Link (optional)</label>
                    <input
                        type="url"
                        className="form-control"
                        id="videoLink"
                        placeholder="Provide a link to video evidence"
                        value={videoLink}
                        onChange={(e) => setVideoLink(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email for Report Tracking</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Submit Report</button>
            </form>

            {successMessage && (
                <div className="alert alert-success mt-4" role="alert">
                    {successMessage}
                </div>
            )}
            {errorMessage && (
                <div className="alert alert-danger mt-4" role="alert">
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

export default ReportForm;
