import React from 'react'
import { NavLink } from 'react-router-dom'
import './Home.css'

const Home = () => {
    return (
        <main>
            <section id="players" className="section bg-image-2">
                <div className="content text-center">
                    <h1 className="text-white">List of cheating players in Hunt: Showdown 1896</h1>
                    <p className="text-white">See a list of cheating players - You can join the discord to make an instant report</p>
                    <NavLink to="/ReportForm" className="btn btn-primary btn-lg">REPORT PLAYER</NavLink>
                </div>
            </section>
            <section id="reporting" className="section bg-image-2">
                <div className="grid-container container">
                    <div className="grid-item img">
                        <img src="/img/vnerv.png" alt="Image 1" />
                    </div>
                    <div className="grid-item content">
                        <p>The following players have been reported for using cheats in Hunt: Showdown. We encourage the community to report suspicious behavior to help keep the game fair for everyone.</p>
                    </div>
                    <div className="grid-item content">
                        <p>Remember, Hunt: Showdown is a game that thrives on skill and strategy. Cheating undermines the spirit of competition and spoils the experience for everyone.</p>
                    </div>
                    <div className="grid-item img">
                        <img src="/img/hunt-2.jpg" alt="Image 2" />
                    </div>
                    <div className="grid-item img">
                        <img src="/img/0c4jejunxhd51.webp" alt="Image 3" />
                    </div>
                    <div className="grid-item content">
                        <p>These players have been verified as cheaters through multiple reports and confirmed violations of fair play in Hunt: Showdown. Stay vigilant and play fair.</p>
                    </div>
                    <div className="grid-item content">
                        <p>Together, we can protect the integrity of Hunt: Showdown. Keep an eye out for cheaters, report them, and let's enjoy the game the way it was meant to be played.</p>
                    </div>
                    <div className="grid-item img">
                        <img src="/img/hunt-3.webp" alt="Image 4" />
                    </div>
                </div>
            </section>

            <section id="aboutus" className="section bg-image-3">
                <div className="content text-center">
                    <h1 className="text-white">About us</h1>
                    <p className="text-white">
                        Welcome to Cloud Shield, your trusted anti-cheat platform for Hunt: Showdown.

                        At Cloud Shield, our mission is to maintain the integrity and fairness of Hunt: Showdown by providing a transparent and community-driven platform to expose players who engage in cheating. We believe in a level playing field where skill, strategy, and teamwork are the keys to success—not unfair advantages through dishonest means.

                        Our list of reported and verified cheaters is carefully curated based on community reports and in-game observations. We work alongside players like you to ensure that Hunt: Showdown remains a game of tactical brilliance and competitive balance.

                        Cheating disrupts the enjoyment and fairness of the game, and we are committed to rooting out these behaviors for the benefit of the entire player base. With your help, we can protect the hunt and preserve the spirit of competition.

                        Whether you're here to check the list, report a cheater, or simply support our cause, we thank you for being part of the fight for fair play. Together, we can safeguard the game we love and ensure that Hunt: Showdown stays fun and fair for everyone.</p>
                </div>
            </section>

            <section id="discord" className="section bg-image-4">
                <div className="content text-center">
                    <h1 className="text-white">Join Us on Discord</h1>
                    <p className="text-white">Engage with the community on Discord.</p>
                    <a href="https://discord.gg/NBRsmXA6" className="btn btn-primary btn-lg">
                        JOIN DISCORD
                    </a>
                </div>
            </section>
        </main>
    )
}

export default Home
