import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import ReportForm from './components/ReportForm';
import { SpeedInsights } from "@vercel/speed-insights/react";
import Players from './components/Players';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ReportForm" element={<ReportForm />} />
          <Route path="/Players" element={<Players />} />
        </Routes>
        <Footer />
      </Router>
      <SpeedInsights />
    </div>
  );
}

export default App;
