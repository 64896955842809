import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const isHome = location.pathname === '/';

    return (
        <header>
            <nav className={`navbar navbar-expand-lg navbar-dark bg-dark ${isHome ? 'fixed-top' : ''}`} id="menu_var">
                <div className="container-fluid">
                    <NavLink to="/" className="navbar-brand">
                        <span className="report-title">Hunt Showdown: 1896 Reporting</span>
                    </NavLink>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="Home">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#players">Players</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#reporting">Reporting</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#aboutus">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#discord">Discord</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/Players">♰ Reported Players ♰</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
